import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import { VideoPage } from './components/views/VideoPage'
import Checkout from './components/checkout/Checkout';
import LandingPage from './components/landing/LandingPage';


import './App.css'
import PricingView from './components/views/PricingView'
import LogInView from './components/views/LogInView'

const colors = ["#FF1493", "#fffc00"]

const queryClient = new QueryClient()

const theme = createTheme({
    palette: {
        primary: {
            // main: `${colors[Math.floor(Math.random() * 2)]}`,
            main: '#FF1493',
            second: '#e9ecef',
            third: '#f2f2f2',
            bg: '#fcfcfc'
        },
        links: {
            main: '#fdfffc',
            icon: "#03045e",
            text: "#fefefe",
            bg: "#03045e",
        },
        text: {
            regular: "#f6f6f6",
            second: '#211A1E',
            gray: '#8a817c',
        }
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/pricing" element={<PricingView />} />
                        <Route path="/login" element={<LogInView />} />
                        {/* <VideoPage />  */}
                        {/* <Checkout /> */}
                    </Routes>
                </BrowserRouter>
            </QueryClientProvider>
        </ThemeProvider>
    )
}

export default App;


// import React, { useState, useEffect } from 'react';
// import { googleLogout, useGoogleLogin } from '@react-oauth/google';
// import axios from 'axios';

// function App() {
//     const [user, setUser] = useState([]);
//     const [profile, setProfile] = useState([]);

//     const login = useGoogleLogin({
//         onSuccess: (codeResponse) => setUser(codeResponse),
//         onError: (error) => console.log('Login Failed:', error)
//     });

//     useEffect(
//         () => {
//             if (user) {
//                 axios
//                     .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
//                         headers: {
//                             Authorization: `Bearer ${user.access_token}`,
//                             Accept: 'application/json'
//                         }
//                     })
//                     .then((res) => {
//                         setProfile(res.data);
//                     })
//                     .catch((err) => console.log(err));
//             }
//         },
//         [user]
//     );

//     // log out function to log the user out of google and set the profile array to null
//     const logOut = () => {
//         googleLogout();
//         setProfile(null);
//     };

//     return (
//         <div>
//             <h2>React Google Login</h2>
//             <button onClick={() => login()}>Sign in with Google 🚀 </button>

//         </div>
//     );
// }
// export default App;