import * as React from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

function GetStartedVibioForm() {
    const [vibioUsername, setVibioUsername] = React.useState('')

    const handleVibioUsernameChange = (event) => {
        setVibioUsername(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        // You can add your form submission logic here
        console.log('Submitted username:', vibioUsername)
        // Clear the email field after submission
        setVibioUsername('')
    }

    return (
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'catamaran',
        }}>
            <TextField
                label="vibio.co/"
                variant="outlined"
                size="medium"
                type="text"
                placeholder='your name'
                value={vibioUsername}
                onChange={handleVibioUsernameChange}
                sx={{
                    mr: 1.2,
                    border: 'none',
                    backgroundColor: '#fff',
                    gap: 4,
                    fontFamily: 'catamaran',
                    borderRadius: '10px'
                }}
            />
            <Button
                type="submit"
                variant="contained"
                disableElevation
                sx={{
                    p: '.8rem .6rem',
                    fontFamily: 'inherit',
                    backgroundColor: 'primary.main',
                    color: 'text.regular',
                    textTransform: 'none',
                }}
            >
                <span style={{ fontSize: '1rem' }}>Claim My Vibio</span>
            </Button>
        </Box>
    )
}

export default GetStartedVibioForm
