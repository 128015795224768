import { Box, Button, Divider, Typography } from "@mui/material"
import React from "react"

const navigation = ["Explore", "Pricing", "Log In", "Contact Us"]

export default function Footer() {
    return (
        <>
            <Box sx={{
                backgroundColor: 'primary.main',
                color: 'text.regular',
                fontFamily: 'Catamaran',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                alignItems: 'center',
                p: 1,
                gap: 1,
            }}>

                <Box sx={{
                }}>
                    {navigation.map((nav) =>
                        <Button
                            type="button"
                            variant="text"
                            disableElevation
                            size='small'
                            sx={{
                                fontFamily: 'inherit',
                                backgroundColor: 'primary.main',
                                color: 'text.regular',
                                textTransform: 'none',
                            }}
                        >
                            {nav}
                        </Button>
                    )}

                </Box>

                <Divider variant="middle" sx={{ color: 'primary.second', width: '80%' }} component="div" />

                <Box sx={{}}>
                    <Typography variant='body1' component="p" sx={{
                        fontFamily: 'inherit',
                        fontWeight: 400,
                        color: 'inherit',
                    }}>
                        Vibio &copy; {new Date().getFullYear()} All Rights Reserved
                    </Typography>
                </Box>
            </Box>
        </>
    )
}
