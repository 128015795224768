import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { GoogleOAuthProvider } from "@react-oauth/google"

ReactDOM.render(
  <GoogleOAuthProvider clientId='1072530727433-nqosc6b4ihpqqe8uf9bs7rik16uepjbv.apps.googleusercontent.com'>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root')
)

reportWebVitals()
