import { Box, Typography } from "@mui/material";
import React from "react"

function PricingView() {
    // Paste the stripe-pricing-table snippet in your React component
    return (
        <>
            <Box sx={{}}>
                <Typography
                    variant="h3"
                    component="h3"
                    sx={{
                        color: 'text.second',
                        fontWeight: 900,
                        fontFamily: 'inherit',
                        textAlign: 'center',
                        m: 2,
                        p: 1,
                    }}
                >
                    Pricing
                </Typography>
                <stripe-pricing-table
                    pricing-table-id="prctbl_1P9oJYL5Yp4iIJ8uIY7n9bzT"
                    publishable-key="pk_live_51OzVQYL5Yp4iIJ8ugu6vQCItmGZUCnNYEEzdeoLBsv8Azd47cRCNzOAfvYJ1xTk0safA7pyoYBWmuUeS0YC7wZJW00ccHmUSkF"
                >
                </stripe-pricing-table>
            </Box>
        </>
    );
}

export default PricingView