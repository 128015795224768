import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

const pages = [{ name: 'Explore', href: '#explore' }, { name: 'Pricing', href: '/pricing' }];

function Navbar() {
    const isMobile = useMediaQuery('(max-width:1050px)')

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogin = () => {
        console.log("Logging in...")
    }

    return (
        <AppBar position="static" sx={{
            backgroundColor: 'primary.main',
            color: 'primary.bg',
            p: 1.4,
        }}>
            <Container maxWidth="xl">
                <Toolbar sx={{
                    display: 'flex',
                    justifyContent: isMobile ? 'space-between' : 'space-around',
                    alignItems: 'center',
                    m: 0,
                    fontFamily: 'Catamaran',
                }}>

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            fontSize: '2.2rem',
                            fontWeight: 800,
                            fontFamily: 'inherit',
                        }}
                    >
                        Vibio
                    </Typography>


                    <Button onClick={handleLogin}
                        component={Link}
                        to='/login' sx={{
                            display: { xs: 'flex', md: 'none' },
                            backgroundColor: 'primary.bg',
                            color: 'primary.main',
                            fontWeight: 600,
                            fontSize: "1rem",
                            fontFamily: 'inherit',
                            textTransform: 'none',
                        }}>
                        Log In
                    </Button>

                    <Box>
                        <Box sx={{
                            display: { xs: 'none', md: 'flex' },
                            m: '2 0',
                            p: '0 1rem',
                            gap: 6,
                        }}>
                            {pages.map((page) => (
                                <Button
                                    key={page.name}
                                    component={Link}
                                    to={`${page.href}`}
                                    sx={{
                                        color: 'inherit',
                                        fontSize: "1rem",
                                        fontWeight: 600,
                                        fontFamily: 'Catamaran',
                                        textTransform: 'none',
                                    }}
                                >
                                    {page.name}
                                </Button>
                            ))}
                        </Box>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu} sx={{
                                    fontWeight: 600,
                                    fontFamily: 'Catamaran',
                                    textTransform: 'none',
                                }}>
                                    {page.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Button onClick={handleLogin}
                        component={Link}
                        to='/login'
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            color: 'primary.bg',
                            fontWeight: 600,
                            fontSize: "1rem",
                            fontFamily: 'inherit',
                            textTransform: 'none',
                        }}>
                        Log In
                    </Button>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navbar;
