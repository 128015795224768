import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import GoogleIcon from '@mui/icons-material/Google';
import { Button } from '@mui/material';

function GoogleLoginButton() {
    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState([]);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            // console.log('Google login successful', codeResponse)
            setUser(codeResponse)
        },
        onError: (error) => console.log('Login Failed:', error)
    })

    useEffect(() => {
        if (user) {
            axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: 'application/json'
                }
            })
                .then((res) => {
                    setProfile(res.data);
                })
                .catch((err) => console.log(err));
        }
    }, [user]);

    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                startIcon={<GoogleIcon />}
                onClick={login}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    textAlign: 'center',
                    textTransform: 'none',
                    gap: 1,
                    '& .MuiSvgIcon-root': {
                        width: '18px', // Adjust icon size as needed
                        height: '18px',
                        color: '#1877F2',
                    },
                    backgroundColor: 'primary.bg',
                    color: 'text.second'
                }}
            >
                Continue with Google
            </Button>
        </>
    );
}

export default GoogleLoginButton
