import { Box, Typography, useMediaQuery } from "@mui/material"
import React from "react"
import GetStartedVibioForm from "./GetStartedVibioForm"

export default function GetStartedReminderSection() {
    const isMobile = useMediaQuery('(max-width:1050px)')

    return (
        <>
            <Box sx={{
                m: 0,
                p: isMobile ? '.4rem .2rem' : '2rem 4rem',
                fontFamily: 'catamaran',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 360,
                flexWrap: 'wrap',
                backgroundColor: 'primary.bg',
                color: 'text.second',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Box sx={{
                    m: isMobile ? '1rem 0' : '2rem 2.2rem',
                    p: isMobile ? '1rem 1.2rem' : .4
                }}>
                    <Typography variant={isMobile ? 'h4' : 'h3'} component="h3"
                        sx={{
                            color: 'primary.main',
                            fontFamily: 'inherit',
                            fontWeight: 900,
                        }}>
                        Reserve Your Spot
                    </Typography>
                    <Typography variant="body1" component="p"
                        sx={{
                            fontFamily: 'inherit',
                            fontWeight: 400,
                        }}>
                        Build your video-bio page today with Vibio.
                    </Typography>
                </Box>
                <Box sx={{ m: 0, p: 0, pb: 4, }}>
                    <GetStartedVibioForm />
                </Box>
            </Box>
        </>
    )
}
