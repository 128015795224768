import React from 'react';
import axios from 'axios'; // Use Axios or any other HTTP library for making requests
import { Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';

function FacebookLoginButton() {
    const handleFacebookLogin = async () => {
        try {
            // Send request to backend to initiate OAuth2 authentication with Google
            const response = await axios.get('http://localhost:8080/auth/google'); // Example endpoint for Google OAuth2 authentication
            // Handle successful response from backend (e.g., redirect user or update UI)
        } catch (error) {
            // Handle error (e.g., display error message to user)
            console.error('Error initiating Google login:', error);
        }
    };

    return (
        <Button
            variant="contained"
            color="primary"
            startIcon={<FacebookIcon />}
            onClick={handleFacebookLogin}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                textAlign: 'center',
                textTransform: 'none',
                gap: 1, // Adjust as needed
                '& .MuiSvgIcon-root': {
                    width: '18px', // Adjust icon size as needed
                    height: '18px',
                    color: '#1877F2',
                },
                backgroundColor: 'primary.bg',
                color: 'text.second'
            }}
        >
            Continue with Facebook
        </Button>
    );
};

export default FacebookLoginButton;
