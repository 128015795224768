import React from "react"
import HookSection from "./sections/HookSection"
import Footer from "./sections/Footer"
import Navbar from "../nav/Navbar"
import BenefitsSection from "./sections/BenefitsSection"
import { Box } from "@mui/material"
import GetStartedReminderSection from "./sections/GetStartedReminderSection"
import FeedbackSection from "./sections/FeedbackSection"
import PricingView from "../views/PricingView"

import { GoogleLogin } from '@react-oauth/google'


const benefits = [
    {
        title: 'Engage',
        content: "Vibio is different than other link in bio tools; with Vibio you can upload a video that reaches out to your audience, tells the stories you want, maximizing your engagement with your audience.",
        alignment: 'left',
    },
    {
        title: 'All In One',
        content: "You can link your TikTok, Instagram, Twitter, Website, calendar or anything you have here on Vibio! Unlimited links allows your users to easily find you on your other platforms.",
        alignment: 'right',
    },
    {
        title: 'Easy-peasy',
        content: "Within few minutes you can get your Vibio account up and running. Of course, you are welcome take as much time as you need to craft the page that best describes you!",
        alignment: 'left',
    },
]

export default function LandingPage() {
    const handleLoginSuccess = (response) => {
        // Handle successful login
        console.log('Login success:', response);
    };

    const handleLoginFailure = (error) => {
        // Handle login failure
        console.error('Login failure:', error);
    };

    return (
        <>
            <Navbar />
            <HookSection />
            {benefits.map((benefit) =>
                <BenefitsSection
                    title={benefit.title}
                    content={benefit.content}
                    alignment={benefit.alignment}
                />
            )}
            <PricingView />
            <GetStartedReminderSection />
            {/* <FeedbackSection /> */}
            <Footer />
        </>
    )
}
