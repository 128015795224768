import { Box, Button, Card, CardMedia, Paper, Typography, useMediaQuery } from "@mui/material"
import React from "react"


const benefits = [
    {
        title: 'Engage',
        content: "Vibio is different than other link in bio tools; with Vibio you can upload a video that reaches out to your audience, tells the stories you want, maximizing your engagement with your audience.",
        bgColor: 'primary.bg',
        alignment: 'right',
    },
    {
        title: 'All In One',
        content: "You can link your TikTok, Instagram, Twitter, Website, calendar or anything you have here on Vibio! Unlimited links allows your users to easily find you on your other platforms.",
        bgColor: '#f8f8f8',
        alignment: 'left',
    },
    {
        title: 'Easy-peasy',
        content: "Within few minutes you can get your Vibio account up and running. Of course, you are welcome take as much time as you need to craft the page that best describes you!",
        bgColor: 'primary.bg',
        alignment: 'right',
    },
]

export default function BenefitsSection({ title, content, alignment }) {
    const isMobile = useMediaQuery('(max-width:1050px)')

    return (
        <>
            <Box sx={{
                m: 0,
                p: isMobile ? '.4rem .2rem' : '2rem',
                fontFamily: 'catamaran',
                display: 'flex',
                flexDirection: alignment === 'left' ? 'row' : 'row-reverse',
                flexWrap: 'wrap',
                backgroundColor: `${alignment}` === 'left' ? 'primary.third' : 'primary.bg',
                color: 'primary.main',
            }}>

                {/* outer container */}
                <Box sx={{
                    m: isMobile ? '1rem 0' : '2rem 2.2rem',
                    p: isMobile ? '1rem 1.2rem' : '2rem 5rem',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : (`${alignment}` === 'right' ? 'row-reverse' : 'row'),
                    flexWrap: 'wrap',
                    gap: 2,
                }}
                >
                    {/* benefit description box */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        gap: 2,
                        // textAlign: `${alignment}`,
                        m: 0,
                        pb: 2,
                        justifyContent: 'center'
                    }}>

                        {/* title */}
                        <Typography variant={isMobile ? 'h3' : 'h3'} component="h2"
                            sx={{
                                fontFamily: 'inherit',
                                fontWeight: 900,
                            }}>
                            {title}
                        </Typography>

                        {/* content text */}
                        <Typography variant='body1' component="p" sx={{
                            fontFamily: 'inherit',
                            fontWeight: 500,
                            color: 'text.second',
                            textAlign: 'left',
                        }}>
                            {content}
                        </Typography>

                        {/* get started button */}
                        <Button
                            type="button"
                            variant="contained"
                            disableElevation
                            size='large'
                            sx={{
                                width: isMobile ? '100%' : '33%',
                                p: '.8rem .6rem',
                                fontFamily: 'inherit',
                                backgroundColor: 'primary.main',
                                color: 'text.regular',
                                textTransform: 'none',
                            }}
                        >
                            Get Started
                        </Button>
                    </Box>

                    {/* image box */}
                    <Box sx={{
                        flex: 1,
                        p: 1,
                        alignSelf: 'center',
                    }}>
                        <CardMedia
                            component="img"
                            height="380px"
                            width="auto"
                            image="https://picsum.photos/400"
                            alt="Reasons to use Vibio, image"
                            sx={{
                                borderRadius: '20px',
                                justifySelf: 'center',
                            }}
                        />
                    </Box>

                </Box>
            </Box >
        </>
    )
}
