import { Box, CardMedia, Typography } from "@mui/material"
import React from "react"
import GetStartedVibioForm from "./GetStartedVibioForm"
import { useMediaQuery } from '@mui/material'


export default function HookSection() {
    const isMobile = useMediaQuery('(max-width:1050px)')

    return (
        <>
            <Box sx={{
                backgroundColor: 'primary.bg',
                color: 'primary.main',
                m: isMobile ? '1rem 0' : '2rem 2.2rem',
                p: isMobile ? '1rem 0' : '4rem 6rem',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                flexWrap: 'wrap',
                minHeight: 660,
            }}>

                {/* text section */}
                <Box sx={{
                    flex: 1,
                    textAlign: 'left',
                    m: 0,
                    p: '0 1.2rem',
                    fontWeight: 600,
                    fontFamily: 'Catamaran',
                }}>

                    {/* banner */}
                    <Box sx={{
                        m: 0,
                        mt: 1,
                        p: '0 .2rem',
                        '& > *': {
                            mb: '1.8rem',
                        },
                    }}>
                        <Typography variant={isMobile ? 'h3' : 'h2'} component="h3" sx={{ fontFamily: 'inherit', fontWeight: 800, mb: 2 }}>
                            Your bio, your way: video-first link in bio tool!
                        </Typography>

                        <Typography variant="h6" component="h5" sx={{ fontFamily: 'inherit', fontWeight: 500, mb: 4, maxWidth: '90%' }}>
                            Vibio lets you turn your link-in-bio page into a video, so you can get creative 
                            to best express yourself and what you have to offer!
                        </Typography>

                        <GetStartedVibioForm />
                    </Box>


                </Box>

                {/* media section */}
                <Box sx={{
                    flex: 1,
                    m: 0,
                    p: 0
                }}>
                    <CardMedia
                        component="video"
                        autoPlay
                        muted
                        loop
                        src="/vibio-mvp-demo.mp4"
                        title="Your Video Title"
                    />
                </Box>
            </Box>
        </>
    )
}
