import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { googleLogout, useGoogleLogin } from '@react-oauth/google'

import { Box, Button, Card, Typography } from "@mui/material"

import GoogleLoginButton from "../buttons/GoogleLoginButton"
import FacebookLoginButton from "../buttons/FacebookLoginButton"
import TwitterLoginButton from "../buttons/TwitterLoginButton"
// import GoogleLogin_ from '../buttons/GoogleLogin_'


export default function LogInView() {

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                m: 0,
                p: 0,
                gap: 1,
                height: '100vh',
                fontFamily: 'Catamaran',
                backgroundColor: 'primary.main',
            }}>
                {/* L */}
                <Box sx={{
                    flex: 3,
                    // border: '1px orange solid',
                    color: 'text.regular',
                    m: 1,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Typography
                        variant="h1"
                        component="h1"
                        sx={{
                            fontWeight: 900,
                            fontFamily: 'inherit',
                            textAlign: 'center',
                        }}
                    >
                        Vibio
                    </Typography>
                    <Typography
                        variant="h6"
                        component="h6"
                        sx={{
                            fontWeight: 500,
                            fontFamily: 'inherit',
                            textAlign: 'center',
                        }}
                    >
                        the first video link-in-bio tool
                    </Typography>
                </Box>

                {/* R */}
                <Box sx={{
                    flex: 2,
                    // border: '1px cyan solid',
                    backgroundColor: 'primary.bg',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {/* Log In Container */}
                    <Box sx={{
                        // textAlign: 'center'
                        m: 1,
                        p: 2,
                        // border: '1px #000 solid'
                    }}>
                        <Typography
                            variant="h4"
                            component="h4"
                            sx={{
                                fontWeight: 700,
                                fontFamily: 'inherit',
                                textAlign: 'center',
                            }}
                        >
                            Welcome to Vibio
                        </Typography>
                        <Typography
                            variant="body1"
                            component="p"
                            sx={{
                                fontWeight: 400,
                                fontFamily: 'inherit',
                                textAlign: 'center',
                            }}
                        >
                            Let's get you started
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 2,
                            p: 1,
                            gap: 2,
                        }}>

                            {/* <Button variant="outlined" sx={{ textTransform: 'none' }}>Continue with Google</Button> */}
                            <GoogleLoginButton />

                            <FacebookLoginButton />
                            <TwitterLoginButton />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
